import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import moment from 'moment';

import backendApi from '../apis/backend';
import publicBucket from '../apis/publicBucket';

import { Link } from 'react-router-dom';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
    },
}));

const formatPercentage = (numerator: number, denominator: number) => {
    return `${((numerator / denominator) * 100).toFixed(1)}%`;
};

const renderSparklineCell = (rune: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
            <SparkLineChart
                data={rune.mePriceSeries?.map((price: any) => price.cFP * 1000) || []}
                width={100}
                height={32}
                plotType='line'
                showHighlight
                showTooltip
                colors={['hsl(210, 98%, 42%)']}
                xAxis={{
                    scaleType: 'band',
                    data: rune?.mePriceSeries?.map((price: any) => moment.utc(price.ts).format('MMM DD, HH:mm')) || [],
                }}
            />
        </div>
    );
};

export default function HootestRunesMinted() {
    const fetchMintsInBlock = async () => {
        const { data } = await backendApi.get('rune/get_runes_minted_last_block');
        return data;
    };

    const {
        data: mintsInBlock,
        isLoading,
        error,
    }: { data: any; isLoading: boolean; error: any } = useQuery('mintsInBlock', fetchMintsInBlock, {
        refetchInterval: 60000, // 60000 ms = 1 minute
    });

    if (error) {
        return <div>Error fetching data</div>;
    }

    if (isLoading) {
        return (
            <Box
                sx={{
                    height: '100%',
                    width: { xs: '100%', md: '100%' },
                }}
            >
                <CardContent sx={{ width: '100%' }}>
                    <List sx={{ width: '100%' }}>
                        <ListItem alignItems='flex-start'>
                            <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                        </ListItem>
                    </List>
                    <List sx={{ width: '100%' }}>
                        <ListItem alignItems='flex-start'>
                            <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                        </ListItem>
                    </List>
                    <List sx={{ width: '100%' }}>
                        <ListItem alignItems='flex-start'>
                            <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                        </ListItem>
                    </List>
                    <List sx={{ width: '100%' }}>
                        <ListItem alignItems='flex-start'>
                            <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                        </ListItem>
                    </List>
                </CardContent>
            </Box>
        );
    }

    const mintsToShow = mintsInBlock.mints.slice(0, 10);

    return (
        <Box
            sx={{
                width: { xs: '100%', md: '100%' },
            }}
        >
            {/* TODO: extend this to runes most minted in the last X blocks */}
            {/* TODO: get runes image */}
            <Typography component='h2' variant='subtitle2' gutterBottom sx={{ fontWeight: '600', padding: 1.7 }}>
                Top minted in the last hour
            </Typography>
            <List sx={{ width: '100%' }}>
                {mintsToShow.map((mint: any, index: number) => {
                    // TODO: POLL this page every 1 minute?
                    // Add tooltip to the rune item with the rune details?
                    // TODO: maybe add detailed page for both metrics in the home?
                    const supply =
                        (mint.rune.termsAmount * mint.rune.termsCap + mint.rune.premine) / 10 ** mint.rune.divisibility;
                    const totalMinted = (mint.rune.mints * mint.rune.termsAmount) / 10 ** mint.rune.divisibility;
                    const totalMintedInTheBlock =
                        (mint.totalMints * mint.rune.termsAmount) / 10 ** mint.rune.divisibility;
                    const adjustedPremine = mint.rune.premine / 10 ** mint.rune.divisibility;
                    const timePassedSinceEtch = (mintsInBlock.blockheight - mint.rune.block) * 10; // in minutes
                    const formattedTimePassed = moment.duration(timePassedSinceEtch, 'minutes').humanize();
                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                alignItems='flex-start'
                                component={Link}
                                to={`/${mint.rune.spaced_rune}`}
                                style={{ color: 'inherit' }}
                            >
                                <ListItemAvatar>
                                    <Badge
                                        color='secondary'
                                        overlap='circular'
                                        badgeContent={formatPercentage(adjustedPremine + totalMinted, supply)}
                                    >
                                        <Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
                                    </Badge>
                                </ListItemAvatar>

                                <HtmlTooltip
                                    placement='right'
                                    title={
                                        <Card>
                                            <CardContent>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                >
                                                    <strong>
                                                        {mint.rune.spaced_rune} {mint.rune.symbol}
                                                    </strong>
                                                </Typography>
                                                <ul>
                                                    <li>
                                                        <strong>
                                                            {mint.totalMints} (
                                                            {formatPercentage(totalMintedInTheBlock, supply)}){' '}
                                                        </strong>
                                                        mints in the last hour
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            {formatPercentage(adjustedPremine + totalMinted, supply)}{' '}
                                                        </strong>
                                                        of supply minted out
                                                    </li>
                                                    <li>
                                                        <strong>{formatPercentage(adjustedPremine, supply)} </strong>
                                                        of premine
                                                    </li>
                                                    <li>
                                                        Rune was etched <strong>{formattedTimePassed}</strong> ago
                                                    </li>
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    }
                                >
                                    <ListItemText
                                        primary={
                                            <div>
                                                <strong>
                                                    {`${mint.rune.spaced_rune} ${mint.rune.symbol}`.length > 16
                                                        ? `${`${mint.rune.spaced_rune} ${mint.rune.symbol}`.slice(0, 16)}...`
                                                        : `${mint.rune.spaced_rune} ${mint.rune.symbol}`}{' '}
                                                    {mint.rune.symbol}{' '}
                                                    <Chip size='small' color='success' label={`+${mint.totalMints}`} />
                                                </strong>
                                                <br />
                                                <Typography variant='caption' display='inline'>
                                                    {'  '} {formattedTimePassed} ago
                                                </Typography>
                                            </div>
                                        }
                                        // secondary={
                                        //     <React.Fragment>
                                        //         <Typography
                                        //             component='span'
                                        //             variant='body2'
                                        //             sx={{ color: 'text.primary', display: 'inline' }}
                                        //         >
                                        //             {mint.totalMints} ({formatPercentage(totalMintedInTheBlock, supply)})
                                        //             mints
                                        //         </Typography>
                                        //         <br />
                                        //         {formatPercentage(adjustedPremine + totalMinted, supply)} of supply minted
                                        //         out
                                        //         <br />
                                        //         {formatPercentage(adjustedPremine, supply)} of premine
                                        //     </React.Fragment>
                                        // }
                                    />
                                </HtmlTooltip>
                                <ListItemText>{renderSparklineCell(mint.rune)}</ListItemText>
                            </ListItem>
                            {index < mintsToShow.length - 1 && <Divider variant='inset' component='li' />}
                        </React.Fragment>
                    );
                })}
            </List>
        </Box>
    );
}
