import React from 'react';
import ReactDOM from 'react-dom/client';

import { Route, Routes, BrowserRouter, useParams } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';
import App from './Dashboard';

const RenderWallet = () => {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { address } = useParams();

    return <App address={address} />;
};

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <QueryClientProvider client={queryClient}>
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route key={'default'} path={'/:address?'} element={<RenderWallet />}></Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    </QueryClientProvider>
);
