import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import type { ThemeOptions } from '@mui/material/styles';

import { colorSchemes, typography, shadows, shape } from './themePrimitives';

interface AppThemeProps {
    children: React.ReactNode;
    themeComponents?: ThemeOptions['components'];
}

// TODO: reset this file and implement toggle with preference?

export default function AppTheme({ children, themeComponents }: AppThemeProps) {
    const theme = React.useMemo(() => {
        return createTheme({
            palette: {
                mode: 'dark',
                ...colorSchemes.dark.palette,
            },
            // For more details about CSS variables configuration, see https://mui.com/material-ui/customization/css-theme-variables/configuration/
            cssVariables: {
                colorSchemeSelector: 'data-mui-color-scheme',
                cssVarPrefix: 'template',
            },
            colorSchemes: {
                light: false,
                dark: colorSchemes.dark,
            }, // Recently added in v6 for building light & dark mode app, see https://mui.com/material-ui/customization/palette/#color-schemes
            typography,
            shadows,
            shape,
            components: {
                ...themeComponents,
            },
        });
    }, [themeComponents]);
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
