import * as React from 'react';

import moment from 'moment';

import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import backendApi from '../apis/backend';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import RuneHolders from './RuneHolders';
import SessionsChart from './SessionsChart';
import StatCard, { StatCardProps } from './StatCard';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

import OrdersBook from './OrdersBook';

import millify from 'millify';

import { Link } from 'react-router-dom';

import XIcon from '@mui/icons-material/X';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { sanitizeRune } from './Utils';

const blocksPerDay = 144;

// TODO: add team's note, that is manually added as a context

export default function MainGrid(props: any) {
    const {
        isLoading,
        isError,
        data: runeData,
    } = useQuery({
        queryKey: ['users', props.address],
        queryFn: async () => {
            const response = await backendApi.get('rune/get_by_name', {
                params: {
                    spaced_rune: sanitizeRune(props.address),
                },
            });
            return response.data;
        },
    });

    if (isError || runeData === '') {
        return <div>Rune was not found</div>;
    }

    const formatPercentage = (numerator: number, denominator: number) => {
        return `${((numerator / denominator) * 100).toFixed(2)}%`;
    };

    let supply = 0;
    let totalMinted = 0;
    let adjustedPremine = 0;

    let timePassedSinceEtch = 0;
    let formattedTimePassed = '';

    let currentPrice = 0;
    let priceChange = 0;

    if (!isLoading) {
        supply = (runeData.termsAmount * runeData.termsCap + runeData.premine) / 10 ** runeData.divisibility;
        totalMinted = (runeData.mints * runeData.termsAmount) / 10 ** runeData.divisibility;
        adjustedPremine = runeData.premine / 10 ** runeData.divisibility;

        timePassedSinceEtch = (runeData.blockheight - runeData.block) * 10; // in minutes
        formattedTimePassed = moment.duration(timePassedSinceEtch, 'minutes').humanize();

        currentPrice = runeData.mePriceSeries?.[runeData.mePriceSeries?.length - 1].cFP || 0;
        priceChange =
            runeData.mePriceSeries?.[runeData.mePriceSeries?.length - 1].cFP / runeData.mePriceSeries?.[0].cFP;
    }

    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            <br />
            <br />
            {/* cards */}
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <HighlightedCard
                        title='Enjoying the app? Refer us to your crypto goups!'
                        subtitle='Help us grow and improve our products'
                    />
                </Grid> */}
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <React.Fragment>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography variant='h5' component='div'>
                                        {runeData.spaced_rune} {runeData.symbol}
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Etched {formattedTimePassed} ago
                                    </Typography>
                                    <br></br>
                                    <Typography variant='body2' style={{ textAlign: 'right' }}>
                                        {supply} supply
                                    </Typography>
                                    <div style={{ padding: '10px 0' }}>
                                        <LinearProgress
                                            variant='buffer'
                                            value={(100 * totalMinted) / supply}
                                            valueBuffer={(100 * (totalMinted + adjustedPremine)) / supply}
                                        />
                                    </div>
                                    <Typography sx={{ color: 'text.secondary' }} style={{ textAlign: 'right' }}>
                                        {runeData.premine} premine ({formatPercentage(adjustedPremine, supply)})
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }} style={{ textAlign: 'right' }}>
                                        {totalMinted} minted ({formatPercentage(totalMinted, supply)})
                                    </Typography>
                                </div>
                            )}
                        </CardContent>
                    </React.Fragment>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Details
                                    </Typography>
                                    <Stack
                                        direction='column'
                                        sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                    >
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Typography variant='h6' component='p'>
                                                    {runeData.id}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                Rune ID
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <br />
                                    <Stack
                                        direction='column'
                                        sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                    >
                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                            <Stack
                                                direction='row'
                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Typography variant='h6' component='p'>
                                                    <Link
                                                        to={`https://mempool.space/tx/${runeData.etching}`}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        style={{ color: '#ff7500', textDecoration: 'none' }}
                                                    >
                                                        {runeData.etching.slice(0, 6)}...{runeData.etching.slice(-6)}
                                                    </Link>
                                                </Typography>
                                            </Stack>
                                            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                                Etching transaction
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                    ) : (
                        <StatCard
                            title='Market Cap'
                            value='$362K'
                            interval='Last 30 days'
                            trend='up'
                            data={
                                runeData?.mintHistory
                                    ?.map((mint: any) => mint?.cumulativeMints)
                                    ?.slice(-blocksPerDay) || []
                            }
                            xArray={runeData?.mintHistory?.map((mint: any) => mint.block)?.slice(-blocksPerDay) || []}
                        />
                    )}
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={140} style={{ width: '100%' }} />
                    ) : (
                        <StatCard
                            title='Volume'
                            value='$261K'
                            interval='Last 30 days'
                            trend='up'
                            data={
                                runeData?.mintHistory
                                    ?.map((mint: any) => mint?.cumulativeMints)
                                    ?.slice(-blocksPerDay) || []
                            }
                            xArray={runeData?.mintHistory?.map((mint: any) => mint.block)?.slice(-blocksPerDay) || []}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                // TODO: remove if rune was minted out and eteched out after a few months ago
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Price per mint
                                    </Typography>
                                    <Stack sx={{ justifyContent: 'space-between' }}>
                                        <Stack
                                            direction='row'
                                            sx={{
                                                alignContent: { xs: 'center', sm: 'flex-start' },
                                                alignItems: 'flex-end',
                                                gap: 1,
                                            }}
                                        >
                                            <Typography variant='h4' component='p'>
                                                {runeData.mintEstimateSats} sats
                                            </Typography>
                                            <Typography gutterBottom component='p' sx={{ color: 'text.secondary' }}>
                                                ${runeData.mintEstimateUsd}
                                            </Typography>
                                        </Stack>
                                        <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                            Price to mint a bag of {runeData.termsAmount} {runeData.symbol} in the
                                            current fee rate of {runeData.fees?.fastestFee} sats/vB
                                        </Typography>
                                    </Stack>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Total spent in mint
                                    </Typography>
                                    <Stack sx={{ justifyContent: 'space-between' }}>
                                        <Stack
                                            direction='row'
                                            sx={{
                                                alignContent: { xs: 'center', sm: 'flex-start' },
                                                alignItems: 'flex-end',
                                                gap: 1,
                                            }}
                                        >
                                            <Typography variant='h4' component='p'>
                                                {millify(runeData.totalSpentInMintsSats)} sats
                                            </Typography>
                                            <Typography gutterBottom component='p' sx={{ color: 'text.secondary' }}>
                                                ${millify(runeData.totalSpentInMintsUsd)}
                                            </Typography>
                                        </Stack>
                                        <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                            Total USD spent in minting {runeData.mints} bags. This can serve an initial
                                            realized value of the token.
                                        </Typography>
                                    </Stack>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    {(() => {
                        if (isLoading) {
                            return (
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            );
                        } else if (totalMinted + adjustedPremine < supply) {
                            return (
                                <Alert severity='warning' style={{ height: '100%' }}>
                                    The rune is still being minted.
                                    <br />
                                    <br />
                                    Before minting and buying check if it's not a copy of a previous rune.
                                </Alert>
                            );
                        } else {
                            return (
                                <Alert severity='success' style={{ height: '100%' }}>
                                    This rune is already minted out and has enought liquidity to be traded.
                                    <br />
                                    <br />
                                    Still, check if it's not a copy of a previous rune.
                                </Alert>
                            );
                        }
                    })()}
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <Card variant='outlined' sx={{ height: '100%', flexGrow: 1 }}>
                        <CardContent>
                            {isLoading ? (
                                <Skeleton variant='rectangular' width={210} height={120} style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <Typography component='h2' variant='subtitle2' gutterBottom>
                                        Quick links
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            href={`https://magiceden.io/runes/${sanitizeRune(props.address)}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Magic Eden
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            href={`https://twitter.com/search?q=${encodeURIComponent(sanitizeRune(props.address))}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            endIcon={<XIcon />}
                                        >
                                            Twitter
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            href={`https://luminex.io/rune/${encodeURIComponent(sanitizeRune(props.address))}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Luminex
                                        </Button>
                                    </Box>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ sm: 12, md: 6 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={300} style={{ width: '100%' }} />
                    ) : (
                        <SessionsChart
                            title='Price'
                            subtitle={
                                ((
                                    (runeData?.mePriceSeries?.[runeData?.mePriceSeries?.length - 1]?.cFP *
                                        (runeData?.prices?.btc || 0)) /
                                    10 ** 8
                                )?.toFixed(8) || 0) + ` USD / ${runeData.symbol}`
                            }
                            series={[
                                {
                                    id: 'price',
                                    label: 'Price',
                                    showMark: false,
                                    curve: 'linear',
                                    stack: 'total',
                                    area: true,
                                    stackOrder: 'ascending',
                                    data: runeData?.mePriceSeries?.map((price: any) => price.cFP * 1000) || [],
                                },
                            ]}
                            xAxis={[
                                {
                                    scaleType: 'point',
                                    data:
                                        // TODO: adjust to local time
                                        runeData?.mePriceSeries?.map((price: any) =>
                                            moment.utc(price.ts).format('MMM DD, HH:mm')
                                        ) || [],
                                    tickInterval: (index: any, i: any) => (i + 1) % 5 === 0,
                                },
                            ]}
                            titleNumber={
                                ((
                                    (runeData?.mePriceSeries?.[runeData?.mePriceSeries?.length - 1].cFP || 0) * 1000
                                ).toFixed(4) || 0) + ` sats / ${runeData.symbol}`
                            }
                            chipColor={priceChange > 1 ? 'success' : 'error'}
                            chipLabel={
                                priceChange
                                    ? priceChange > 1
                                        ? '+' + (100 * (priceChange - 1)).toFixed(2) + '%'
                                        : '-' + (100 * (1 - priceChange)).toFixed(2) + '%'
                                    : ''
                            }
                        />
                    )}
                </Grid>
                <Grid size={{ sm: 12, md: 6 }}>
                    {/* Add etched and next minted runes */}
                    {/* Mint progression per percentage of supply or value invested */}
                    {/* Value invested per mint */}
                    {/* Add separate pages for runes with mints in progress, this mints details are not relevant in large runes */}
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={300} style={{ width: '100%' }} />
                    ) : (
                        <SessionsChart
                            title='Mints'
                            subtitle='Mint progression until mint out or in the first 7 days (1008 blocks)'
                            series={[
                                {
                                    id: 'mints',
                                    label: 'Mints',
                                    showMark: false,
                                    curve: 'linear',
                                    stack: 'total',
                                    area: true,
                                    stackOrder: 'ascending',
                                    data: runeData.mintHistory.map((mint: any) => mint.cumulativeMints),
                                },
                            ]}
                            xAxis={[
                                {
                                    scaleType: 'point',
                                    data: runeData.mintHistory.map((mint: any) => mint.block),
                                    tickInterval: (index: any, i: any) => (i + 1) % 5 === 0,
                                },
                            ]}
                            titleNumber={runeData.mints}
                            chipColor='primary'
                            chipLabel={
                                runeData?.mintHistory
                                    ? '+' +
                                      (runeData.mintHistory[runeData.mintHistory.length - 1]?.cumulativeMints -
                                          runeData.mintHistory[0]?.cumulativeMints)
                                    : ''
                            }
                        />
                    )}
                </Grid>
                <Grid size={{ xs: 12, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <RuneHolders rune={runeData} holders={runeData.holders} />
                        </Stack>
                    )}
                </Grid>
                <Grid size={{ xs: 12, lg: 3 }}>
                    {isLoading ? (
                        <Skeleton variant='rectangular' width={210} height={500} style={{ width: '100%' }} />
                    ) : (
                        <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                            {/* <CustomizedTreeView /> */}
                            <OrdersBook runeData={runeData} />
                        </Stack>
                    )}
                </Grid>
                {/* Add holders analys. How distributed it is. */}
                {/* Add notable holders */}
                {/* Does it worth to mint now? Get an estimate per bag, price spent in mint */}
                {/* Get links to price */}
                {/* Sentiment analysis on twitter */}
                {/* Get official socials */}
                {/* Add advanced tab on the bottom with deploy tx */}
                {/* Create a separate tab on the left that makes the right panel change with no reloadings*/}
                {/* Add last orders no magic eden */}
                {/* Add last tweets */}
                {/* Icrease price and market cap size?*/}
                {/* Add simple identification information about the rune, like id and number */}
            </Grid>
            <Copyright sx={{ my: 4 }} />
        </Box>
    );
}
