import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const orders = Array.from({ length: 16 }, () => ({
    amount: Math.floor(Math.random() * 10000) + 1,
    priceSats: Math.floor(Math.random() * 100000) + 1,
}));

// TODO: improve this component

export default function OrdersBook(props: any) {
    const btcPrice = 97000;
    const ordersToShow = props?.runeData?.meOrders?.slice(0, 8) || [];
    return (
        <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2'>
                    Open orders on Magic Eden
                </Typography>
                <List>
                    {ordersToShow.map((order: any, index: any) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <div>
                                            <Typography variant='body1' component='span'>
                                                <strong>
                                                    {order.amount} {props.runeData.symbol}
                                                </strong>{' '}
                                                for {order.price} sats
                                            </Typography>
                                            <Typography variant='body2' component='span' sx={{ float: 'right' }}>
                                                {Number(order.formattedUnitPrice).toFixed(2)} sats /{' '}
                                                {props.runeData.symbol}
                                            </Typography>
                                        </div>
                                    }
                                    secondary={`$${((order.price * btcPrice) / 10 ** 8).toFixed(2)}`}
                                />
                            </ListItem>
                            {index < ordersToShow.length - 1 && <Divider component='li' />}
                        </React.Fragment>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}
