import * as React from 'react';
import { useEffect, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import moment from 'moment';

import backendApi from '../apis/backend';
import publicBucket from '../apis/publicBucket';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const formatPercentage = (numerator: number, denominator: number) => {
    return `${((numerator / denominator) * 100).toFixed(2)}%`;
};

export default function RunesTimeline() {
    const [lastAndNextEtchedRunes, setLastAndNextEtchedRunes] = React.useState<any>(null);

    useEffect(() => {
        backendApi
            .get('rune/get_last_and_next_etched_runes')
            .then((response: any) => {
                setLastAndNextEtchedRunes(response.data);
            })
            .catch((error: any) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!lastAndNextEtchedRunes) {
        return (
            <Box sx={{ width: '100%', height: '100%' }}>
                <List sx={{ width: '100%' }}>
                    <ListItem alignItems='flex-start'>
                        <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                    </ListItem>
                </List>
                <List sx={{ width: '100%' }}>
                    <ListItem alignItems='flex-start'>
                        <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                    </ListItem>
                </List>
                <List sx={{ width: '100%' }}>
                    <ListItem alignItems='flex-start'>
                        <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                    </ListItem>
                </List>
                <List sx={{ width: '100%' }}>
                    <ListItem alignItems='flex-start'>
                        <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                    </ListItem>
                </List>
            </Box>
        );
    }

    const runesToShow = lastAndNextEtchedRunes.last.slice(0, 10);

    return (
        <Box
            sx={{
                height: '100%',
                width: { xs: '100%', md: '100%' },
            }}
        >
            <HtmlTooltip
                placement='top'
                title={
                    <React.Fragment>
                        <Typography color='inherit'>Last runes etched</Typography>
                        <br />
                        The runes listed here were etched in the last blocks
                    </React.Fragment>
                }
            >
                <Typography component='h2' variant='subtitle2' gutterBottom sx={{ fontWeight: '600', padding: 1.7 }}>
                    Runes etched in the last block{' '}
                    <InfoIcon
                        style={{
                            fontSize: 15,
                            verticalAlign: 'middle',
                            marginLeft: '5px',
                            color: 'inherit',
                        }}
                    />
                </Typography>
            </HtmlTooltip>
            <List sx={{ width: '100%' }}>
                {runesToShow.map((rune: any, index: number) => {
                    // TODO: POLL this page every 1 minute?
                    // Add tooltip to the rune item with the rune details?
                    // TODO: maybe add detailed page for both metrics in the home?
                    const supply = (rune.termsAmount * rune.termsCap + rune.premine) / 10 ** rune.divisibility;
                    const totalMinted = (rune.mints * rune.termsAmount) / 10 ** rune.divisibility;
                    const adjustedPremine = rune.premine / 10 ** rune.divisibility;
                    const timePassedSinceEtch = (lastAndNextEtchedRunes.blockheight - rune.block) * 10; // in minutes
                    const formattedTimePassed = moment.duration(timePassedSinceEtch, 'minutes').humanize();
                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                alignItems='flex-start'
                                component={Link}
                                to={`/${rune.spaced_rune}`}
                                style={{ color: 'inherit' }}
                            >
                                <ListItemAvatar>
                                    <Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <div>
                                            <strong>
                                                {rune.spaced_rune} {rune.symbol}
                                            </strong>
                                            <br />
                                            <Typography variant='caption' display='inline' gutterBottom>
                                                {'  '} {formattedTimePassed} ago
                                            </Typography>
                                        </div>
                                    }
                                    // secondary={
                                    //     <React.Fragment>
                                    //         <Typography variant='caption' display='inline' gutterBottom>
                                    //             {/* {formatPercentage(adjustedPremine + totalMinted, supply)} of supply
                                    //             minted out
                                    //             <br /> */}
                                    //             {formatPercentage(adjustedPremine, supply)} of premine
                                    //         </Typography>
                                    //     </React.Fragment>
                                    // }
                                />
                            </ListItem>
                            {index < runesToShow.length - 1 && <Divider variant='inset' component='li' />}
                        </React.Fragment>
                    );
                })}
            </List>
        </Box>
    );
}
