import * as React from 'react';

import { useQuery } from 'react-query';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import millify from 'millify';

import moment from 'moment';

import backendApi from '../apis/backend';
import publicBucket from '../apis/publicBucket';

import { Link } from 'react-router-dom';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
    },
}));

const formatPercentage = (numerator: number, denominator: number) => {
    return `${((numerator / denominator) * 100).toFixed(1)}%`;
};

const renderSparklineCell = (rune: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
            <SparkLineChart
                data={rune.mePriceSeries?.map((price: any) => price.cFP * 1000) || []}
                width={100}
                height={32}
                plotType='line'
                showHighlight
                showTooltip
                colors={['hsl(210, 98%, 42%)']}
                xAxis={{
                    scaleType: 'band',
                    data: rune?.mePriceSeries?.map((price: any) => moment.utc(price.ts).format('MMM DD, HH:mm')) || [],
                }}
            />
        </div>
    );
};

export default function TopRunes(props: any) {
    const fetchRunes = async () => {
        const { data } = await backendApi.get('/rune/get_runes_sorted', {
            params: {
                sort: props.field,
                limit: 10,
            },
        });
        return data;
    };

    const {
        data: { runes: runesToShow = [] } = {},
        isLoading,
        error,
        refetch,
    } = useQuery('TopRunes', fetchRunes, {
        refetchInterval: 60000, // Refetch every 1 minute
    });

    if (isLoading) {
        return (
            <List sx={{ width: '100%' }}>
                <ListItem alignItems='flex-start'>
                    <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                </ListItem>
                <ListItem alignItems='flex-start'>
                    <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                </ListItem>
                <ListItem alignItems='flex-start'>
                    <Skeleton variant='rounded' width={210} height={60} style={{ width: '100%' }} />
                </ListItem>
            </List>
        );
    }

    if (error) {
        return <Typography color='error'>Error loading runes</Typography>;
    }

    return (
        <Box
            sx={{
                width: { xs: '100%', md: '100%' },
            }}
        >
            {/* TODO: extend this to runes most minted in the last X blocks */}
            {/* TODO: get runes image */}
            <Typography
                component='h2'
                variant='subtitle2'
                gutterBottom
                sx={{ fontWeight: '600', padding: '6px 10px', margin: 0 }}
            >
                {props.title}{' '}
            </Typography>
            <List sx={{ width: '100%' }}>
                {runesToShow.map((rune: any, index: number) => {
                    // TODO: Add tooltip to the rune item with the rune details?
                    // TODO: maybe add detailed page for both metrics in the home?
                    // TODO: add market cap
                    // TODO: add see more
                    const timePassedSinceEtch = (875000 - rune.etching.runeId.block) * 10; // in minutes
                    const formattedTimePassed = moment.duration(timePassedSinceEtch, 'minutes').humanize();
                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                alignItems='center'
                                component={Link}
                                to={`/${rune.etching.runeName}`}
                                style={{ color: 'inherit' }}
                            >
                                <ListItemAvatar>
                                    <Badge
                                        color={
                                            rune.unitPriceChange > 0
                                                ? 'success'
                                                : rune.unitPriceChange < 0
                                                  ? 'error'
                                                  : 'primary'
                                        }
                                        overlap='circular'
                                        badgeContent={
                                            (rune.unitPriceChange > 0 ? '+' : '') +
                                            rune.unitPriceChange.toFixed(1) +
                                            '%'
                                        }
                                    >
                                        <Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
                                    </Badge>
                                </ListItemAvatar>
                                <HtmlTooltip
                                    placement='right'
                                    title={
                                        <Card>
                                            <CardContent>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                >
                                                    <strong>
                                                        {rune.etching.runeName} {rune.etching.symbol}
                                                    </strong>
                                                </Typography>
                                                <ul>
                                                    <li>
                                                        Rune was etched <strong>{formattedTimePassed}</strong> ago
                                                    </li>
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    }
                                >
                                    <ListItemText
                                        primary={
                                            <div>
                                                <strong>
                                                    {rune.etching.runeName} {rune.etching.symbol}
                                                </strong>
                                                <br />
                                                <Typography variant='caption'>
                                                    <strong>${millify(rune.marketCap * 1000 * 100)}</strong> market cap
                                                    $
                                                </Typography>
                                                <br />
                                                <Typography variant='caption'>
                                                    <strong>${millify(rune.vol * 1000 * 100)}</strong> 24h volume
                                                </Typography>
                                                {/* <br />
                                            <Typography variant='caption'>
                                                {'  '} {formattedTimePassed} ago
                                            </Typography> */}
                                            </div>
                                        }
                                    />
                                </HtmlTooltip>
                                <ListItemText>
                                    <React.Fragment>{renderSparklineCell(rune)}</React.Fragment>
                                </ListItemText>
                            </ListItem>
                            {index < runesToShow.length - 1 && <Divider variant='inset' component='li' />}
                        </React.Fragment>
                    );
                })}
            </List>
        </Box>
    );
}
