import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import ChartUserByCountry from './ChartUserByCountry';
import CustomizedTreeView from './CustomizedTreeView';
import CustomizedDataGrid from './CustomizedDataGrid';
import HighlightedCard from './HighlightedCard';
import PageViewsBarChart from './PageViewsBarChart';
import SessionsChart from './SessionsChart';
import StatCard, { StatCardProps } from './StatCard';

import logo from '../logo.png';

function getDaysInMonth(month: number, year: number) {
    const date = new Date(year, month, 0);
    const monthName = date.toLocaleDateString('en-US', {
        month: 'short',
    });
    const daysInMonth = date.getDate();
    const days = [];
    let i = 1;
    while (days.length < daysInMonth) {
        days.push(`${monthName} ${i}`);
        i += 1;
    }
    return days;
}

export default function MainGrid(props: any) {
    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            <br />
            <br />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src={logo} alt='Logo' width={256} height={256} />
            </div>
            <br />
            <br />
            {/* cards */}
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <StatCard
                        title='Users'
                        value='14k'
                        interval='Last 30 days'
                        trend='up'
                        data={[
                            200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380, 360, 400,
                            380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
                        ]}
                        xArray={getDaysInMonth(4, 2024)}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <HighlightedCard
                        title='Enjoying the app? Refer us to your crypto goups!'
                        subtitle='Help us grow and improve our products'
                    />
                </Grid>
                {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
          <HighlightedCard
            title="Get even more data"
            subtitle="Be a pro member today and have access to advanced features"
            buttonText="Comming soon"
          />
        </Grid> */}
                <Grid size={{ sm: 12, md: 6 }}>
                    <SessionsChart
                        title='Mints'
                        subtitle='Mint progression until mint out or in the first 7 days (1008 blocks)'
                        data={[
                            200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380, 360, 400,
                            380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
                        ]}
                        xArray={getDaysInMonth(4, 2024)}
                        titleNumber={500}
                    />
                </Grid>
                <Grid size={{ sm: 12, md: 6 }}>
                    <PageViewsBarChart />
                </Grid>
            </Grid>
            {/* <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Details
      </Typography> */}
            <Grid container spacing={2} columns={12}>
                <Grid size={{ md: 12, lg: 9 }}>
                    <CustomizedDataGrid />
                </Grid>
                <Grid size={{ xs: 12, lg: 3 }}>
                    <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
                        {/* <CustomizedTreeView /> */}
                        <ChartUserByCountry />
                    </Stack>
                </Grid>
            </Grid>
            <Copyright sx={{ my: 4 }} />
        </Box>
    );
}
