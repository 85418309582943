import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function AreaGradient({ color, id }: { color: string; id: string }) {
    return (
        <defs>
            <linearGradient id={id} x1='50%' y1='0%' x2='50%' y2='100%'>
                <stop offset='0%' stopColor={color} stopOpacity={0.5} />
                <stop offset='100%' stopColor={color} stopOpacity={0} />
            </linearGradient>
        </defs>
    );
}

export default function SessionsChart(props: any) {
    const theme: any = useTheme();
    console.log(theme);

    const [selected, setSelected] = React.useState('1day');

    let series = props.series;
    let xAxis = props.xAxis;

    const blocksPerDay = 24 * 6;
    let recordsToShow = -1;
    switch (selected) {
        case '1day':
            recordsToShow = blocksPerDay;
            break;
        case '7days':
            recordsToShow = blocksPerDay * 7;
            break;
        case '1month':
            recordsToShow = blocksPerDay * 30;
            break;
        default:
            break;
    }

    if (recordsToShow > 0) {
        series = props.series.map((s: any) => {
            return {
                ...s,
                data: s.data.slice(-recordsToShow),
            };
        });
        xAxis = props.xAxis.map((a: any) => {
            return {
                ...a,
                data: a.data.slice(-recordsToShow),
            };
        });
    }

    const colorPalette = [theme.palette.primary.light, theme.palette.primary.main, theme.palette.primary.dark];

    return (
        <Card variant='outlined' sx={{ width: '100%' }}>
            <CardContent>
                <Typography component='h2' variant='subtitle2' gutterBottom>
                    {props.title}
                </Typography>
                <Stack sx={{ justifyContent: 'space-between' }}>
                    <Stack
                        direction='row'
                        sx={{
                            alignContent: { xs: 'center', sm: 'flex-start' },
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <Typography variant='h4' component='p'>
                            {props.titleNumber}
                        </Typography>
                        <Chip size='small' color={props.chipColor} label={props.chipLabel} />
                    </Stack>
                    <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                        {props.subtitle}
                    </Typography>
                </Stack>
                <LineChart
                    colors={colorPalette}
                    xAxis={xAxis}
                    series={series}
                    height={250}
                    margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
                    grid={{ horizontal: true }}
                    sx={{
                        '& .MuiAreaElement-series-price': {
                            fill: "url('#price')",
                        },
                        '& .MuiAreaElement-series-fees': {
                            fill: "url('#fees')",
                        },
                        '& .MuiAreaElement-series-mints': {
                            fill: "url('#mints')",
                        },
                    }}
                    slotProps={{
                        legend: {
                            hidden: true,
                        },
                    }}
                >
                    <AreaGradient color={theme.palette.primary.dark} id='price' />
                    <AreaGradient color={theme.palette.primary.main} id='fees' />
                    <AreaGradient color={theme.palette.primary.light} id='mints' />
                </LineChart>
                <ToggleButtonGroup
                    aria-label='Basic button group'
                    sx={{ justifyContent: 'flex-end', display: 'flex' }}
                    style={{ padding: '0 10px' }}
                    onChange={(event, newSelected) => {
                        setSelected(newSelected?.[0]);
                    }}
                >
                    <ToggleButton value='1day' size='small' selected={selected === '1day'}>
                        1 day
                    </ToggleButton>
                    <ToggleButton value='7days' size='small' selected={selected === '7days'}>
                        7 days
                    </ToggleButton>
                    <ToggleButton value='1month' size='small' selected={selected === '1month'}>
                        1 month
                    </ToggleButton>
                    <ToggleButton value='all' size='small' selected={selected === 'all'}>
                        All
                    </ToggleButton>
                </ToggleButtonGroup>
            </CardContent>
        </Card>
    );
}
